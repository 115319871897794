$primary: rgb(218, 84, 146);
$primary-discret: rgb(255, 234, 245);
$primary-medium: rgb(254, 162, 210);
$secondary: rgb(82, 82, 82);
$blue: rgb(47, 44, 236);
$purple: rgb(143, 44, 236);
$light-discret: hsl(0, 0%, 98%);
$light-medium: hsl(0, 0%, 95%);
$light-dark: hsl(0, 0%, 50%);
$danger: rgb(210, 0, 0);
$success: hsl(142, 76%, 36%);
$hard-discret: rgb(250, 250, 250);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'blue': $blue,
  'purple': $purple,
  'light-discret': $light-discret,
  'light-medium': $light-medium,
  'light-dark': $light-dark,
  'primary-discret': $primary-discret,
  'primary-medium': $primary-medium,
  'danger': $danger,
  'success': $success,
  'hard-discret': $hard-discret,
);
